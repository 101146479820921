@import "sass-lib";
@media all and(max-width: 1910px){
	header{
		.wrapper{
			padding: 0 90px;
			.logo{ left: 0; }
			.rh_head{
				nav{
					.thumb-header{
						.sign{
							a{ 
								padding: 0; display: block;
								img{ display: inline-block; vertical-align: text-top; padding-top: 24px; }
							}
						}
					}
				}
			}
		}
	}
	.bodysec{
		.home-slide{
			.wrapper{
				width: 1260px;
				.nav_slide{
					width: 686px;
					.col{
						.list{
							p{ font-size: 13px; }
						}
					}
				}
				.slider{
					.slide{
						.caption{
							h5{ font-size: 15px; }
							h1{ font-size: 50px; }
							p{ font-size: 15px; }
						}
					}
					.slick-dots{
						right: -50px;
						li{ padding: 13px 5px; }
					}
				}
				.socmed{
					ul li{
						a{ font-size: 13px; }
					}
				}
				.nav_slide{
					.col {
						.list {
							p {min-height: 80px;}
						}
					}
					.slick-dots{ top: -300px; }
				}
			}
			.scroll-down{ right: 10px; }
		}
		.home-about{
			padding-top: 90px;
			&:before{ left:90px; width: 350px; height: 250px; }
			.left{
				margin-left: 220px; width: 430px;
			}
			.right{
				margin-right: 195px; width: 465px;
				.content{
					h5{ font-size: 13px; margin-bottom: 20px; }
					p{ font-size: 13px; }
				}
			}
		}
		.home-promote{
			.slider{ margin: -80px 100px -100px 100px }
		}
		.home-service{
			.highlight{
				.right{ width: 400px; }
			}
			.box-layanan{
				.list{
					.content{ 
						left: 30px; right: 30px; bottom: 30px; 
						.left{ 
							width: 200px; 
							.icon{ width: 40px; }
							h4{ font-size: 25px;  height: 105px; }
						}
						.right{
							.btn-circle{
								&:before{ width:40px; height: 50px; }
							}
						}
					}
				}
			}
		}
		.home-news{
			.wrapper{
				.list{
					.content{
						h3{ font-size: 35px; }
						h5{ font-size: 15px; }
						h4{ font-size: 16px; height: 45px; }
						p{ font-size: 15px; height: 45px; }
					}
				}
				.col{
					.slick-arrow{ width: 30px; height: 30px; }
				}
			}
		}
		.home-client{
			margin-bottom: 70px;
			.wrapper{
				.left{
					.slider{
						.slide{
							.col{
								.list{ width: 251px; }
							}
						}
						&:after{ right: 0px; top: 50%; }
						&:before{ top: 50%; }
						.slick-arrow{
							width: 30px;
							&.slick-next{ bottom: 70px; }
						}
					}
				}
				.right{
					width: 350px; margin-right: 200px; margin-top: 50px;
				}
			}
		}
		.home-map{
			.map{ width: 1170px; height: 450px;}
			.wrapper{
				&:before{ left:50px; right: 50px; }
			}
		}
		.title{ font-size: 40px; }
	}
	.bg-yellow-homepage{ height: 610px; }
	.btn_round{ font-size: 13px; }
	.middle{
		.wrapper{ width: 1170px; margin: 0 auto; }
	}
	.wrap-bot{
		.bg-bot{ margin: 0 -90px; }
	}
	.wrap-lab{
		.wrp-images{ transform: scale(0.7); }
	}
	.banner{
		.capt_abs{
			h1{ font-size: 50px; }
			p{ font-size: 15px; }
			h6{ font-size: 15px; }
		}
	}
	.inner-content{
		padding: 80px 50px 0;
		.tittle-content{
			margin-bottom: 20px;
			h2{ font-size: 30px; line-height: 1.3; }
		}
		.bg_top_left{
			&.long{ height: 850px; width: 355px; }
		}
	}
	.banner-bottom-abs{
		figure{ width: auto; }
		.text{
			ul li{ font-size: 15px; }
		}
	}
	.bg-yellow-small{ height: 440px; }
	.bg-yellow-bot{
		&.aboutus{ bottom: 700px; height: 600px; }
	}
	.headline-content{
		.left{
			.title{ 
				font-size: 30px; 
				&:before{ height:40px; }
			}
		}
		.right{ 
			width: 650px;
			p{ font-size: 13px; height: 40px; } 
		}
	}
	.top-title{
		.filter-top{ padding-top: 40px; }
	}
	.list_laporan{
		.ls{
			p{ margin-left: 0; }
		}
	}
	.ls_faci{
		li{ width: 17%; }
	}
	.milestone{
		.col{
			h3{ font-size: 30px; }
			.box{
				.left{ 
					padding-left: 70px; 
					.images{ width: 395px; height: auto; }
				}
				.right{
					.content{
						width: 100%;
						ul{ 
							margin-left: 0; 
							li{
								p{ margin-left: 0; font-size: 13px; }
								h5{ font-size: 15px; }
							}
						}
					}
				}
			}
		}
	}
	.mini-banner-content{ width: 800px; float: right; }
	.slider-client-misi{ 
		width: 1117px; 
		.slick-arrow{ right: -50px; }
	}
	.qoutes{
		.banner-client{
			h2{ font-size: 40px; }
		}
		.slider-qoutes{
			p{ font-size: 15px; }
		}
	}
	.title-pg{ font-size: 30px; }
	.detail-page{
		.lf-content{
			padding: 0 50px;
			> h1{ font-size: 30px; }
		}
		p{ margin-left: 0; }
		&.det-event{
			.lf-content{
				iframe{ height: 300px; }
			}
		}
	}
	.sub-detail{ font-size: 15px; }
	.packet-box{
		.col{
			.list{ 
				width: 32%; margin-right: 21px; 
				.not-price{ font-size: 15px; }
				.btn_round{ left: 47%; transform: translateX(-47%); }
			}
		}
	}
	.info-periode{ margin-bottom: 30px; }
	.quote{
		h4{ font-size: 15px; }
	}
	.box-event{
		.list{
			.content{
				h4{ height: unset; }
			}
		}
	}
	.bg_bf{ height: 320px;  }
	.wrap-lab{
		.wrp-absolute{
			.abs{
				&.img1{ top: 30px; }
				&.img2{ bottom: -100px; }
				&.img3{ top: 30px; }
			}
		}
		.wrp-images{ padding-top: 150px; margin-bottom: -50px; }
	}
	footer{
		font-size: 13px;
		.wrapper{ margin: 0 50px; }
		.darkbox{ 
			padding: 33px 40px 64px 40px; 
			.middle-footer{
				.col{
					padding: 7px 15px 7px 5px; margin-left: 10px;
					p, ul li{ font-size: 12px; }
					.left{ margin-right: 15px; }
					&:nth-child(2){ margin-left: 10px; }
				}
			}
		}
		.bottom-footer{ margin: 17px 40px 0 40px; }
	}
}
@media all and(max-width: 1400px){
	.bodysec{
		.home-slide{
			.wrapper{
				.slider{
					.slick-dots{ right: -50px; }
				}
			}
		}
		.home-service {
			.box-layanan {
				.slick-arrow {
					bottom: 90px; right: -50px;
					&.slick-prev {bottom: 140px;}
				}
			}
		}
		.home-client{
			.wrapper{
				.left{
					.slider{
						&:after{ top:49.5%; right:-35px; }
						&:before{ top:49.5%; }
					}
				}
			}
		}
	}
}